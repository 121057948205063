import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@Components/application/GrayPaper';
import Button, { ButtonStyles } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  CaseAccountDetailsNode,
  RestrictedCaseServiceProviderNode,
  DocumentNode,
  RestrictedDeathCaseNode,
  DeathCaseNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate, checkIsDate, stringToDateNotNull } from '@Utils/dates';
import { somethingWrongHandler } from '@Utils/modal';

import styles from '../SummaryInfoDeceased/SummaryInfoDeceased.scss';

export interface SummaryCaseDetailsProps {
  caseDetails: CaseAccountDetailsNode[];
  userAccountType?: string;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  instructionLetters?: DocumentNode[];
  accessCode?: string;
  isLifeCase?: boolean;
  deathCase: DeathCaseNode | RestrictedDeathCaseNode;
}

const SummaryCaseDetails: React.FunctionComponent<SummaryCaseDetailsProps> = ({
  caseDetails,
  userAccountType,
  deathCaseServiceProvider,
  instructionLetters,
  accessCode = '',
  isLifeCase = false,
  deathCase,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);

  const accDetailRows = [];

  function parseValue(fieldValue: string) {
    return checkIsDate(fieldValue) ? languageBasedDate(stringToDateNotNull(fieldValue), currentLanguage) : fieldValue;
  }

  function extractValue(input: any) {
    try {
      // Attempt to parse as JSON-like string
      const formattedInput = input.replace(/'/g, '"');
      const parsedObject = JSON.parse(formattedInput);

      // Check if parsedObject is a valid object with `value` and `label`
      if (typeof parsedObject === 'object' && parsedObject !== null && 'value' in parsedObject) {
        return parsedObject.value; // Return the `value` if it exists
      }
    } catch (error) {
      // If parsing fails, treat input as a normal string
    }

    // If input is not a JSON-like object, return it as-is
    return input.trim(); // Return the string after trimming spaces
  }

  for (let i = 0; i < caseDetails?.length; i += 1) {
    const firstLabel = caseDetails[i].key;

    const extractedValue = extractValue(caseDetails[i].value);
    const firstValue = extractedValue.replace(';', ' ');

    accDetailRows.push(
      <Row constant columnMobile>
        <Col>
          <DataWithLabel data={parseValue(firstValue)} label={firstLabel} />
        </Col>
      </Row>
    );
  }

  const hasWillValue = () => {
    let answer = '-';
    if (deathCase.hasWill === true) {
      answer = 'Yes';
    } else if (deathCase.hasWill === false) {
      answer = 'No';
    }
    return answer;
  };

  return (
    <Paper
      width="width-full"
      className={classNames(
        userAccountType ? styles.professionalPaper : styles.normalGrayPaper,
        styles.sameHeight,
        styles.flexBox
      )}
    >
      <div className={styles.deceasedContainer}>
        {deathCaseServiceProvider && (
          <Row justifyEnd constant>
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                }
              }}
              linkTitle="Something wrong?"
              onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
            />
          </Row>
        )}
        <CardTitle
          heading={t('label_account_details' as Messages)}
          description={
            isLifeCase ? t('label_lc_account_details' as Messages) : t('label_dc_account_details' as Messages)
          }
        />
      </div>

      {accDetailRows}
      <Row constant columnMobile>
        <Col>
          <DataWithLabel data={hasWillValue()} label="Is there a will?" />
        </Col>
      </Row>
      {instructionLetters && instructionLetters.length > 0 && (
        <Row className={styles.requestIdDcoument}>
          <Button
            style={ButtonStyles.transparent}
            onClick={() => window.open(instructionLetters[0].file)}
            icon="download"
            iconSize={IconSizes.ss}
            constant
            isFullWidth
          >
            <Typography msg="Download request letter" tag="span" />
          </Button>
        </Row>
      )}
    </Paper>
  );
};

export default SummaryCaseDetails;
