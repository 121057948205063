export enum Messages {
  // TO DO Remove msg_ to keep consistency

  ToDoAddText = 'to_do_add_text', // Do not touch this, it is placeholder for styling
  // App
  appName = 'msg_app_name',
  home = 'link_home',
  appEmail = 'app_email',

  // Header
  linkSettings = 'link_settings',
  linkLogout = 'link_logout',
  backToDashboardLink = 'link_back_to_dashboard',

  // General / shared
  labelYes = 'label_yes',
  labelNo = 'label_no',
  labelOk = 'label_ok',
  buttonBack = 'button_back',
  buttonSkip = 'button_skip',
  buttonNext = 'button_next',
  buttonSave = 'button_save',
  buttonUpdate = 'button_update',
  buttonLoadMore = 'button_load_more',
  buttonLoadLast = 'button_load_last',
  buttonActions = 'button_actions',
  buttonGoBack = 'button_go_back',
  buttonCancel = 'button_cancel',
  buttonSearch = 'button_search',
  buttonRemove = 'button_remove',
  buttonNewDC = 'button_new_dc',

  // Tooltips
  tooltipIdNotVerified = 'label_tooltip_id_not_verified',
  tooltipIdVerified = 'label_tooltip_id_verified',
  tooltipDelete = 'label_tooltip_delete',
  tooltipArchive = 'label_tooltip_archive',
  tooltipDownload = 'label_tooltip_download',
  tooltipTransfer = 'label_tooltip_transfer',
  tooltipEdit = 'label_tooltip_edit',
  tooltipRole = 'label_tooltip_role',
  tooltipMissingInfo = 'label_tooltip_missing_info',
  tooltipMissingDocument = 'label_tooltip_missing_document',
  tooltipConfirmation = 'label_tooltip_confirmation',
  tooltipConfirmationLC = 'label_tooltip_confirmation_lc',
  tooltipOpenDocument = 'label_tooltip_open_document',
  tooltipDeleteDocument = 'label_tooltip_delete_document',
  tooltipNoDeleteCertificate = 'tooltip_no_delete_certificate',
  tooltipDeleteCollaborator = 'tooltip_delete_collaborator',
  tooltipEditCollaborator = 'tooltip_edit_collaborator',
  tooltipReferenceCode = 'tooltip_reference_code',
  tooltipResendInvitation = 'tooltip_resend_invitation',

  // Page titles
  pageLogin = 'msg_page_login',
  pageRegister = 'msg_page_register',
  pageForgotPassword = 'msg_page_forgot_password',
  pageResetPassword = 'msg_page_reset_password',
  pageInvite = 'page_invite',
  pageInviteDesc = 'page_invite_desc',
  pageDashboard = 'page_dashboard',
  pageProviderDCInfo = 'page_provider_dc_info',
  pageProviderDashboard = 'page_provider_dashboard',
  pageSettings = 'msg_page_settings',
  pageCreateDeathCase = 'page_create_dc',
  pageCreateLifeCase = 'page_create_lc',
  pageDeathCases = 'page_death_cases',
  pageLifeCases = 'page_life_cases',
  pageDCServices = 'page_dc_services',
  pageLCServices = 'page_lc_services',
  pageDCDocuments = 'page_dc_documents',
  pageCollaborators = 'page_collaborators',
  pagePayments = 'page_payments',
  titleCase = 'title_case',
  titleInvoices = 'title_invoices',
  titlePending = 'title_pending',
  staffDashboard = 'staff_dashboard',

  //Restrictions
  noSufficientRights = 'no_sufficient_rights',

  // Breadcrumbs
  breadcrumbDeathCase = 'breadcrumb_dc_services',
  breadcrumbLifeCase = 'breadcrumb_lc_services',
  breadcrumbCollaborators = 'breadcrumb_collaborators',
  // Cookies
  cookiesButtonAccept = 'cookies_button_accept',
  cookiesText = 'cookies_text',
  // other
  unexpectedError = 'unexpected_error',
  errorOccurred = 'error_occurred',
  JWTTokenExpiredOrInvalid = 'msg_error_jwt_token_expire_or_invalid',
  // Validations
  errorFieldRequired = 'msg_error_field_required',
  errorInvalidEmail = 'msg_error_invalid_email',
  errorInvalidPhone = 'msg_error_invalid_phone',
  errorPasswordFormat = 'msg_error_password_format',
  errorNotAllowedChars = 'msg_error_not_allowed_chars',
  errorInvalidAccountNumber = 'msg_error_invalid_account_number',
  errorInvalidSortCode = 'msg_error_invalid_sort_code',
  errorPasswordsNotMatch = 'msg_error_passwords_do_not_match',
  errorOverMaxLength = 'msg_error_over_max_length',
  errorMinLength = 'msg_error_min_text_length',
  errorSelector = 'msg_error_selector_value_is_required',
  errorOnlyNumbersAllowed = 'msg_error_only_numbers_allowed',
  // Input Fields
  fieldName = 'msg_field_name',
  fieldSurname = 'msg_field_surname',
  fieldPersonalNo = 'msg_field_personal_no',
  fieldEmail = 'msg_field_email',
  fieldOtp = 'msg_field_otp',
  fieldPhone = 'msg_field_phone',
  fieldProviderId = 'msg_field_provider_id',
  fieldPassword = 'msg_field_password',
  fieldConfirmPassword = 'msg_field_confirm_password',
  fieldOldPassword = 'field_old_password',
  fieldOldPasswordPlaceholder = 'field_old_password_placeholder',
  fieldPasswordPlaceholder = 'field_password_placeholder',
  fieldConfirmPasswordPlaceholder = 'filed_confirm_password_placeholder',
  fieldCardNumber = 'field_card_number',
  fieldCardHolder = 'field_card_holder',
  fieldExpiryDate = 'field_expiry_date',
  fieldCvcCode = 'field_cvc_code',
  defaultSelectPlaceholder = 'label_default_select_placeholder',

  // Auth route
  welcomeSubtitle = 'msg_label_welcome_subtitle',
  welcomeTitle = 'msg_label_welcome_title',
  welcomeIntroductionSubtitle = 'msg_label_welcome_introduction_subtitle',
  registerFormTitle = 'msg_label_register_form_title',
  registerFormSubtitle = 'msg_label_register_form_subtitle',
  loginFormTitle = 'msg_label_login_form_title',
  loginFormSubtitle = 'msg_label_login_form_subtitle',
  resetPasswordFormTitle = 'msg_label_reset_form_title',
  forgotPasswordFormSubtitle2 = 'msg_label_forgot_form_subtitle_link_sent',
  resetPasswordFormSubtitle2 = 'msg_label_reset_form_subtitle_changed',
  labelNoAccount = 'label_no_account',
  labelHaveAccount = 'label_have_account',
  labelResetEmailLinkSent = 'msg_label_reset_link_sent',
  labelVerificationLinkSent = 'msg_label_verification_link_sent',
  questionForgot = 'msg_question_forgot_password',
  questionDidNotReceive = 'msg_question_dont_see_email',
  labelResetSuccess = 'msg_label_reset_success',
  labelOtpSent = 'msg_otp_sent',
  notificationPassswordChangeSuccess = 'notification_password_change_success',
  labelVerifyStepSubtitlePrefix = 'label_verify_',
  labelVerifyStepDescriptionPrefix = 'label_verify_description_',
  labelOr = 'label_or',
  linkLogin = 'msg_link_login',
  buttonLogin = 'button_login',
  buttonGetOtp = 'button_get_otp',
  buttonRegister = 'button_register',
  buttonSignUp = 'button_signup',
  buttonForgotPassword = 'button_forgot_password',
  buttonResetPassword = 'button_reset_password',
  buttonResend = 'button_resend_email',
  invitePageTitle = 'label_invite_page_title',
  invitePageSubTitle = 'label_invite_page_subtitle',
  labelBrokenInviteLink = 'label_broken_invite_link',
  labelInviteExplain = 'label_invite_explain',

  labelVerifiedUser = 'label_verified',
  labelNotVerified = 'label_not_verified',
  fieldReferralSourcePrefix = 'field_referral_source_',

  // Settings route
  titleSettings = 'msg_title_settings',
  titleSettingsSubItemPrefix = 'msg_title_settings_subitem_',
  subTitleSettingsSubItemPrefix = 'msg_subtitle_settings_subitem_',
  titleSettingsUserVerifyPrefix = 'msg_title_settings_user_verify',
  subtitleSettingsUserVerifyPrefix = 'msg_subtitle_settings_user_verify',
  accountType = 'label_account_type',
  labelUnfinished = 'msg_label_unfinished',
  buttonUpdateAccount = 'msg_label_update_account',
  buttonEdit = 'button_edit',
  buttonValidateAgain = 'button_validate_again',
  buttonUpdateIdValidation = 'button_update_id_validation',
  buttonSendEmail = 'button_send_email',
  accountTypePrefix = 'label_account_type_',
  accountTypeDescriptionPrefix = 'label_account_type_description_',
  personalSectionHeader = 'personal_section_header',
  contactSectionHeader = 'contact_section_header',
  emailSubText = 'email_sub_text',
  addressSectionHeader = 'address_section_header',
  fieldBirthDate = 'msg_field_date_of_birth',
  fieldBirthDay = '',
  fieldBirthYear = '',
  fieldPostalCode = 'msg_field_postal_code',
  fieldCity = 'msg_field_city',
  fieldAddress1 = 'msg_field_address_line_1',
  fieldAddress2 = 'msg_field_address_line_2',
  labelStatusTitlePrefix = 'label_status_title_',
  labelStatusSubtitlePrefix = 'label_status_subtitle_',
  labelAccountSettingsIncomplete = 'label_account_settings_incomplete',
  labelAccountSettingsIncompleteSubtitle = 'label_account_settings_incomplete_subtitle',
  notificationMailSentSuccess = 'notification_mail_sent_success',

  fieldcompanyName = 'field_company_name',
  fieldcompanyVat = 'field_company_vat',
  fieldcompanyNumber = 'field_company_number',
  companyAddressSectionHeader = 'company_address_section_header',
  multiFactorAuthHeader = 'multi_factor_auth_header',
  multiFactorAuthQuestion = 'multi_factor_auth_question',
  multiFactorAuthDesc = 'multi_factor_auth_desc',
  multiFactorTrueOption = 'multi_factor_true_option',
  multiFactorFalseOption = 'multi_factor_false_option',
  buttonUpdateCompanyInfo = 'btn_update_company_info',

  titleSettingsAccountSection = 'label_settings_account_section',
  titleSettingsCompanySection = 'label_settings_company_section',

  // Dashboard route
  labelLastDC = 'label_last_dc',
  labelLastDCNotes = 'label_last_dc_notes',
  linkGoDeathCases = 'link_go_death_cases',
  labelLastLC = 'label_last_lc',
  labelLastLCNotes = 'label_last_lc_notes',
  labelNoDeathCasesTitle = 'label_no_dc_title',
  labelNoDeathCasesSubtitle = 'label_no_dc_subtitle',
  labelNoLifeCasesTitle = 'label_no_lc_title',
  labelNoLifeCasesSubtitle = 'label_no_lc_subtitle',
  linkGoLifeCases = 'link_go_life_cases',
  linkCreateDeathCase = 'link_create_death_case',
  linkCreateLifeCase = 'link_create_life_case',

  // DC route
  titleCreateDC = 'title_create_dc',
  titleCreateDCSubItemPrefix = 'title_create_dc_subitem_',
  subTitleCreateDCSubItemPrefix = 'subtitle_create_dc_subitem_',
  fieldProviderPrefix = 'field_provider_',
  funeralDateNotes = 'funeral_date_notes',
  partnerNotifiedNotes = 'partner_notified_notes',
  executorNotifiedNotes = 'executor_notified_notes',
  fieldFirstName = 'field_first_name',
  fieldMiddleName = 'field_middle_name',
  fieldLastName = 'field_last_name',
  fieldDeathDate = 'field_date_of_death',
  fieldFuneralDate = 'field_date_of_funeral',
  fieldDCAddress1 = 'field_dc_address_line_1',
  fieldDCAddress2 = 'field_dc_address_line_2',
  fieldDeathCertificateNo = 'field_dc_certificate_no',
  fieldDcRegisteredDate = 'field_dc_registered_date',
  fieldPartnersFirstName = 'field_partners_first_name',
  fieldPartnersLastName = 'field_partners_last_name',
  fieldPartnersEmail = 'field_partners_email',
  fieldPartnersPhone = 'field_partners_phone',
  fieldRelationship = 'field_relationship',
  fieldBaseCaseProperty = 'field_base_case_property',
  fieldOccupancyStatus = 'field_occupancy_status',
  labelRelationshipPrefix = 'label_relationship_',
  labelBaseCasePropertyPrefix = 'label_bc_property_',
  labelOccupancyStatusPrefix = 'label_occupancy_status_',
  labelActingTypePrefix = 'label_acting_type_',
  labelHasCertificate = 'label_have_certificate',
  labelHasPartner = 'label_has_partner',
  labelLCHasPartner = 'label_lc_has_partner',
  labelAreYouPartner = 'label_are_you_partner',
  labelPartnerUtilitiesRequired = 'label_are_partner_utilities_required',
  labelPartnerToBeNotified = 'label_are_partner_to_be_notified',
  labelHasWill = 'label_has_will',
  labelHasRepresentationLetter = 'label_has_representation_letter',
  labelHasExecutors = 'label_has_executors',
  labelMeExecutor = 'label_me_executor',
  labelNotifyPartner = 'label_notify_partner',
  fieldExecutiveFirstName = 'field_executive_first_name',
  fieldExecutiveLastName = 'field_executive_last_name',
  fieldExecutiveEmail = 'field_executive_email',
  fieldExecutivePhone = 'field_executive_phone',
  fieldShouldNotifyExecutor = 'field_should_notify_executor',
  fieldExecutorNo = 'field_executor_no',
  fieldActingCapacity = 'field_acting_capacity',
  fieldHasAuthority = 'field_has_authority',
  fieldHasAuthorityLC = 'field_has_authority_lc',
  fieldTerms = 'field_terms_and_conditions',
  buttonAddExecutor = 'button_add_executor',
  buttonSaveExecutor = 'button_save_executor',
  buttonRemoveExecutor = 'button_remove_executor',
  labelUploadFileTitle = 'label_upload_file_title',
  labelWillBeNotified = 'label_will_be_notified',
  labelWillNotBeNotified = 'label_will_not_be_notified',
  labelUploadFileDescription = 'label_upload_file_description',
  buttonSend = 'button_send',
  buttonSendAll = 'button_send_all',
  notificationDCDeleteSuccess = 'notification_dc_delete_success',
  notificationLCDeleteSuccess = 'notification_lc_delete_success',
  notificationDCArchiveSuccess = 'notification_dc_archive_success',
  notificationDCUnarchiveSuccess = 'notification_dc_unarchive_success',
  notificationTransformSuccess = 'notification_transform_success',
  fieldAppliedDate = 'field_date_applied',
  tabServiceProviders = 'tab_service_providers',
  tabCollaborators = 'tab_collaborators',
  tabDocuments = 'tab_documents',
  tabSummary = 'tab_summary',
  tabSubscriptions = 'tab_subscriptions',
  rolePrefix = 'role_',
  labelPendingDeletion = 'label_pending_deletion',
  labelFilesUploaded = 'label_files_uploaded',
  labelRepresentationLetter = 'label_representation_letter',
  titleMissingCaseInfo = 'title_missing_case_info',
  labelPassPartnersUtility = 'label_pass_partners_utility',
  messageResendNotification = 'msg_resend_notification',
  messageSuccessResendNotification = 'msg_success_resend',

  // Collaborators
  notificationCollaboratorDeleteSuccess = 'notification_collaborator_delete_success',
  collaboratorsPermissionsMessage = 'collaborators_permissions_message',
  collaboratorsTabText = 'collaborators_tab_text',
  documentsTabText = 'documents_tab_text',
  notificationCollaboratorInvitationResent = 'collaborator_invitation_resent',
  labelVerifiedBy = 'label_verified_by',
  labelOnDate = 'label_on_date',

  // DC Service Provider route
  notificationDCSPDeleteSuccess = 'notification_dc_sp_delete_success',

  // LC route
  titleCreateLC = 'title_create_lc',
  titleCreateLCSubItemPrefix = 'title_create_lc_subitem_',
  subTitleCreateLCSubItemPrefix = 'subtitle_create_lc_subitem_',
  labelIsYourself = 'label_is_yourself',
  labelWhoFor = 'label_who_for',
  labelPerson = 'label_person',

  // Document route
  titleAddDocument = 'title_add_document',
  titleDocuments = 'title_documents',
  titleDocumentType = 'title_document_type',
  titleDocumentTitle = 'title_document_title',
  titleDocumentActions = 'title_document_actions',
  documentTypePrefix = 'document_type_',
  documentTypeDescriptionPrefix = 'document_type_description_',
  transUnionCheckHeader = 'trans_union_check_header',
  transUnionSubTxt1 = 'trans_union_seb_text_1',
  transUnionSubTxt2 = 'trans_union_seb_text_2',
  transUnionSubTxt3 = 'trans_union_seb_text_3',
  msgUserVerificationConsent = 'msg_user_verification_consent',

  // Payments/Card/Subscriptions
  titlePayments = 'title_payments',
  labelNoPaymentCards = 'label_no_payment_cards',
  labelNoPaymentCardsSubtitle = 'label_no_payment_cards_subtitle',
  labelCardHolder = 'label_card_holder',
  labelExpirationDate = 'label_expiration_date',
  buttonAddPaymentCard = 'button_add_payment_card',
  subtitlePaymentsCardName = 'subtitle_payments_card',
  buttonRemoveCard = 'button_remove_card',
  buttonSubscribe = 'button_subscribe',
  buttonUnubscribe = 'button_unsubscribe',
  titleSubscribed = 'title_subscribed',
  titleNotSubscribed = 'title_not_subscribed',
  titleSubscriptionEnds = 'title_subscription_ends',
  titleNextPayment = 'title_next_payment',
  notificationAddStripeCardSuccess = 'notification_add_stripe_card_success',
  notificationRemoveStripeCardSuccess = 'notification_remove_stripe_card_success',
  labelCaseDeleted = 'label_case_deleted',
  titleSubscriptionEndsWithoutCard = 'title_subscription_ends_without_card',

  // Cards
  labelInfoMissing = 'label_info_missing',
  labelYourRole = 'label_your_role',
  labelSent = 'label_sent',
  labelArchive = 'label_archive',
  labelUnarchive = 'label_unarchive',
  labelNotificationAttention = 'label_notification_attention',

  // Yoti
  notificationYotiVerified = 'notification_yoti_verified',
  labelYotiMediaFiles = 'yoti_media_files',

  // Death cases board
  labelPending = 'label_pending',
  labelActive = 'label_active',
  labelCompleted = 'label_completed',
  labelArchived = 'label_archived',
  titleDeathCasesActive = 'title_death_cases_active',
  titleDeathCasesArchived = 'title_death_cases_archived',

  //Service providers board
  labelReadyToSend = 'label_ready_to_send',
  labelConfirmed = 'label_confirmed',
  buttonAddProvider = 'button_add_provider',
  buttonDocuments = 'button_documents',
  buttonCollaborators = 'button_collaborators',
  buttonPayments = 'button_payments',
  titleMissingInfoBlock = 'title_missing_info_block',
  labelMissingPersonalDetails = 'label_missing_personal_details',
  labelMissingDCDetails = 'label_missing_dc_details',
  labelMissingIDValidation = 'label_missing_id_validation',
  labelIdNotVerified = 'label_id_not_verified',
  notificationProviderDeleteSuccess = 'notification_provider_delete_success',
  labelDiscoveryDefnDesc = 'label_discovery_defn_desc',
  buttonMoreInfo = 'button_more_info',

  // Providers page
  titleProviderAccessPage = 'label_provider_access_page',
  titleProviderAccessPageSubtitle = 'label_provider_access_page_subtitle',
  titleProviderAccessPageHelperText = 'label_provider_access_page_helper_text',
  labelBirthDate = 'label_birth_date',
  labelAccessCode = 'label_access_code',
  fieldAccessCode = 'field_access_code',
  labelProviderAccountId = 'label_provider_account_id',
  labelPartnersName = 'label_partners_name',
  labelAddress = 'label_address',
  labelExecutorsName = 'label_executors_name',
  labelCertificate = 'label_certificate',
  labelWill = 'label_will',
  labelCategory = 'label_category',
  labelSubCategory = 'label_subcategory',
  labelNotifierName = 'label_notifier_name',
  labelNotifierEmail = 'label_notifier_email',
  labelNotifierPhone = 'label_notifier_phone',
  labelNotifierRelationship = 'label_notifier_relationship',
  labelProvider = 'label_provider',
  titleProviderDetails = 'title_provider_details',
  labelProviderFeedback = 'label_provider_feedback',
  labelDeceasedDetails = 'label_deceased_details',
  labelDeathCertificateDetails = 'label_death_certificate_details',
  labelNotifierDetails = 'label_notifier_details',
  labelExecutorsDetails = 'label_executors_details',
  labelPartnerDetails = 'label_partner_details',
  linkNewProviderRequest = 'link_new_provider_request', //Can't find your provider? Make a new request
  titleNewProviderRequest = 'title_new_provider_request', //Request a New Service Provider
  labelNewProviderRequestPageHelperText = 'label_new_provider_request_page_helper_text', //Is the Service Provider you are looking for, is missing in the list? Send us the name of the provider and we shall add then at the earliest possible.
  fieldNewProviderName = 'field_new_provider_name', //New Provider Name
  buttonRequest = 'button_request', //Request
  fieldComments = 'field_comments', //Comments

  // Collaborators route
  titleCollaborators = 'title_collaborators',
  buttonAddCollaborator = 'button_add_collaborator',
  titleCollaboratorRelationship = 'title_collaborator_relationship',
  titleCollaboratorName = 'title_collaborator_name',
  titleCollaboratorEmail = 'title_collaborator_email',
  titleCollaboratorPermissions = 'title_collaborator_permissions',
  titleCollaboratorStatus = 'title_collaborator_status',
  titleCollaboratorActions = 'title_collaborator_actions',
  labelRelationshipTypePrefix = 'label_relationship_type_',
  labelPermissionTypePrefix = 'label_permission_type_',
  labelInvitePending = 'label_invite_pending',
  labelCollaboratorRelationship = 'label_collaborator_relationship',
  labelCollaboratorPermission = 'label_collaborator_permission',
  modalOverFreeProvidersTitle = 'modal_over_free_providers_title',
  modalOverFreeProvidersSubtitle = 'modal_over_free_providers_subtitle',

  // Death Case display page
  buttonDownloadCSV = 'button_download_scv',
  labelUserDetails = 'label_user_details',
  labelDeathCaseDetails = 'label_death_case_details',
  labelEnterRefNumber = 'label_enter_ref_number',
  fieldReferenceNumber = 'field_reference_number',
  buttonSubmit = 'button_submit',
  labelID = 'label_id',
  labelPhone = 'label_phone',
  labelAccountNumber = 'label_account_number',
  labelBankSortCode = 'label_bank_sort_code',

  // Modals
  modalPrivacyTitle = 'modal_privacy_title',
  modalRemoveDocumentTitle = 'modal_remove_document_title',
  modalRemoveDocumentSubtitle = 'modal_remove_document_subtitle',
  modalCannotRemoveCertificateTitle = 'modal_cannot_remove_certificate_title',
  modalCannotRemoveCertificateSubtitle = 'modal_cannot_remove_certificate_subtitle',
  modalRemoveDCTitle = 'modal_remove_dc_title',
  modalRemoveDCSubtitle = 'modal_remove_dc_subtitle',
  modalRemoveActiveDCSubtitle = 'modal_remove_active_dc_subtitle',
  modalRemoveDCSPTitle = 'modal_remove_dc_sp_title',
  modalRemoveDCSPSubtitle = 'modal_remove_dc_so_subtitle',
  modalArchiveDCTitle = 'modal_archive_dc_title',
  modalArchiveDCSubtitle = 'modal_archive_dc_subtitle',
  modalConvertToDCTitle = 'modal_convert_to_dc_title',
  modalConvertToDCSubtitle = 'modal_convert_to_dc_subtitle',
  modalConvertToDCSecondTitle = 'modal_convert_to_dc_second_title',
  modalConvertToDCSecondSubtitle = 'modal_convert_to_dc_second_subtitle',
  modalUnarchiveDCTitle = 'modal_unarchive_dc_title',
  modalUnarchiveDCSubtitle = 'modal_unarchive_dc_subtitle',
  modalProviderFormTitle = 'modal_provider_form_title',
  modalProviderFormSubtitle = 'modal_provider_form_subtitle',
  modalUploadDocumentTitle = 'modal_upload_document_title',
  modalUploadDocumentSubtitle = 'modal_upload_document_subtitle',
  modalInviteCollaboratorTitle = 'modal_invite_collaborator_title',
  modalInviteCollaboratorSubtitle = 'modal_invite_collaborator_subtitle',
  modalEditCollaboratorTitle = 'modal_edit_collaborator_title',
  modalEditCollaboratorSubtitle = 'modal_edit_collaborator_subtitle',
  modalRemoveCollaboratorTitle = 'modal_remove_collaborator_title',
  modalRemoveCollaboratorSubtitle = 'modal_remove_collaborator_subtitle',
  modalUserInputtTitle = 'modal_user_input_title',
  modalUserInputSubtitle = 'modal_user_input_subtitle',
  modalUploadChatDocumentTitle = 'modal_upload_chat_document_title',
  modalUploadChatDocumentSubTitle = 'modal_upload_chat_document_subtitle',

  modalRemoveCardTitle = 'modal_remove_card_title',
  modalRemoveCardSubtitle = 'modal_remove_card_subtitle',
  modalRemoveSubscriptionTitle = 'modal_remove_subscription_title',
  modalRemoveSubscriptionSubtitle = 'modal_remove_subscription_subtitle',

  //Footers
  labelPrivacyPage = 'label_privacy_page',
  labelTermsConditionPage = 'label_terms_condition_page',
  labelContactUsPage = 'label_contact_us_page',

  //Summary
  labelPostalCode = 'label_postal_code',
  labelCity = 'label_city',
  labelDeceased = 'label_deceased',
  labelDeathCertificateName = 'label_death_certificate_name',
  labelDeathCertificateNo = 'label_death_certificate_no',
  labelDocumentType = 'label_document_type',
  labelDocumentFileName = 'label_document_file_name',
  labelNoDocument = 'label_no_document',
  labelNoDeathDate = 'label_no_death_date',
  labelNoPartner = 'label_no_partner',
  labelNoExecutor = 'label_no_executor',
  labelView = 'label_view',

  //tour
  labelRegisterDeathDescription = 'label_register_death_description', //Click here to enter the details and accounts of someone who has recently died.
  labelRegisterLifeDescription = 'label_register_life_description', //Click here to start registering the details and accounts of someone who is still alive.
  labelServiceProvidersDescription = 'label_service_providers_description', //This is where you will see all the companies you have selected and follow their progress.
  labelCollaboratorsDescription = 'label_collaborators_description', //Add a family member, friend, executor or professional to help you through your notification journey.
  labelDocumentsDescription = 'label_documents_description', //Add in additional documents you might need to notify companies.
  labelSummaryDescription = 'label_summary_description', //Everything you have added to this registration viewable in one place.
  labelIdVerficationDescription = 'label_id_verification_description', //Click here to verify your identity if not verified
  labelAddProviderDescription = 'label_add_provider_description', //Click here to add a new company and enter the account details.
  labelSubscriptionsDescription = 'label_subscriptions_description', //Everything you have added to this registration viewable in one place.

  labelValidProofType = 'label_valid_proof_type',
  proofUploadSubtitle = 'proof_upload_subtitle',
  labelPassport = 'label_passport',
  labelNationalId = 'label_national_id',
  userDocumentTypePrefix = 'user_document_type_',
  pageValidation = 'page_validation',
  pageTitleError = 'page_title_error',
  pageSubtitleError = 'page_subtitle_error',
  backToPrevStep = 'back_to_previous_step',

  pageTitleSuccess = 'page_title_success',
  pageSubTitleSuccess = 'page_subtitle_success',
  labelNotifierNote = 'label_notifier_note',
  labelNotifierInformation = 'label_notifier_information',
  labelVerifiedAt = 'label_verified_at',
  labelAddress1 = 'label_address_1',
  labelAddress2 = 'label_address_2',
  buttonRequestAccess = 'button_request_access',
  labelCaseAccDetails = 'label_case_acc_details',
  labelProfessionalType = 'label_professional_type',
  labelCompanyName = 'label_company_name',
  labelPositionInCompany = 'label_position_in_company',
  professionalTypePrefix = 'professional_type_description_',
  labelReferralSource = 'label_referral_source',
  labelReferralSourceDetails = 'label_referral_source_details',
  labelPowerOfAttorney = 'label_power_of_attorney',
  labelInstructionLetter = 'label_instruction_letter',
  labelLetterToCompany = 'label_letter_to_company',

  buttonUpload = 'button_upload',
  fieldSkipPeriod = 'field_skip_period',
  labelNotificationSent = 'label_notification_sent',
  labelNotificationConfirmed = 'label_notification_confirmed',
  labelNotifierCard = 'label_notifier_card',
  labelPartnerCard = 'label_partner_card',
  labelExecutorCard = 'label_executor_card',
  buttonUpgradeNow = 'button_upgrade_now',
  buttonCheckout = 'button_checkout',
  pagePaymentSuccess = 'page_payment_success',
  pageStripeSubtitlePaymentSuccess = 'page_stripe_subtitle_payment_success',
  msgPaymentError = 'msg_payment_error',
  labelDuration = 'label_duration',
  modalPlanUpgradeTitle = 'modal_plan_upgrade_title',
  fieldRoleInCompany = 'field_role_in_company',

  titleNewPayments = 'title_new_payments',
  pageSubtitleNewPayment = 'page_subtitle_new_payment',
  labelRegisteredPrefix = 'label_registered_',
  labelToForCaseLimit = 'label_to_for_case_limit',
  pageStripePaymentSuccess = 'page_stripe_payment_success',
  titleReferenceNumber = 'title_reference_number',
  labelChatDocumentUploadSuccess = 'msg_chat_document_upload_success',

  labelServiceProviderOr = 'label_service_provider_or',
  titleChatMessages = 'title_chat_messages',
  labelProviderName = 'label_provider_name',
  fieldFinalBalance = 'field_final_balance',
  labelSearchProvider = 'label_search_provider',
  fieldChooseLifeCaseDocumentType = 'field_choose_life_case_document_type',

  titleProviderSubItemPrefix = 'msg_title_provider_subitem_',
  subtitleProviderSubItemPrefix = 'msg_subtitle_provider_subitem_',
  labelNew = 'label_new',
  labelDeceasedSurname = 'label_deceased_surname',
  labelProviderRole = 'label_provider_role',
  providerAdminTrueOption = 'provider_admin_true_option',
  providerAdminFalseOption = 'provider_admin_false_option',
  msgTitleProviderCompanySettings = 'msg_title_provider_company_settings',
  msgTitleProviderUsers = 'msg_title_provider_users',
  msgTitleProviderPayments = 'msg_title_provider_payments',
  labelPrimaryBereavementEmail = 'label_primary_bereavement_email',
  labelProviderCompanyLogo = 'label_provider_company_logo',
  buttonProviderLogoUpload = 'button_provider_logo_upload',
  msgAllowNotificationEmails = 'msg_allow_notification_emails',
  msgEnableMultiFactorAuthentication = 'msg_enable_multi_factor_authentication',
  enableMfaTrueOption = 'enable_mfa_true_option',
  enableMfaFalseOption = 'enable_mfa_false_option',
  buttonAddUser = 'button_add_user',
  labelProviderFirstName = 'label_provider_first_name',
  labelProviderLastName = 'label_provider_last_name',
  labelProviderEmailAddress = 'label_provider_email_address',
  labelTooltipDeleteUser = 'label_tooltip_delete_user',
  modalAddUserFormTitle = 'modal_add_user_form_title',
  modalAddUserFormSubtitle = 'modal_add_user_form_subtitle',
  notificationUserAddedSuccess = 'notification_user_added_success',
  labelAssignCaseTo = 'label_assign_case_to',
  notificationProviderUserAssigned = 'notification_provider_user_assigned',
  linkViewNotification = 'link_view_notification',
  modalRemoveUser = 'modal_remove_user',
  msgDeleteServiceProviderUser = 'msg_delete_service_provider_user',
  labelPrimaryBereavementPhone = 'label_primary_bereavement_phone',
  msgTitleProviderProfileSettings = 'msg_title_provider_profile_settings',
  pageConfigureMfa = 'page_configure_mfa',
  titleConfigureMfa = 'title_configure_mfa',
  subtitleConfigureMfa = 'subtitle_configure_mfa',
  linkRaiseNewRequest = 'link_raise_new_request',
  msgTitleProfessionalDeathCases = 'msg_title_professional_death_cases',
  msgSubtitleProfessionalDeathCases = 'msg_subtitle_professional_death_cases',
  titleLinkViewNotification = 'title_link_view_notification',
  titleActionEditRemove = 'title_action_edit_remove',
  titleDataDefnInputLabel = 'title_data_defn_input_label',
  titleDataDefnInputType = 'title_data_defn_input_type',
  titleDataDefnInputValues = 'title_data_defn_input_values',
  titleDataDefnIsPrimaryIdentifier = 'title_data_defn_is_primary_identifer',
  titleDataDefnIsMandatory = 'title_data_defn_is_mandatory',
  msgDeleteDataDefinition = 'msg_delete_data_definition',
  modalRemoveDataDefinition = 'modal_remove_data_definition',
  subtitleDataDefinitionsPage = 'subtitle_data_definitions_page',
  tooltipDeleteDataDefinition = 'tooltip_delete_data_definition',
  tooltipEditDataDefinition = 'tooltip_edit_data_definition',
  buttonAddDataDefinition = 'button_add_data_definition',
  labelDataDefnInputTypePrefix = 'label_data_defn_input_type_',
  msgSuccessDataDefinitionAdded = 'msg_success_data_definition_added',
  modalDataDefinitionFormTitle = 'modal_data_definition_form_title',
  modalDataDefinitionFormSubtitle = 'modal_data_definition_form_subtitle',
  msgCommaSeparationPlaceHolder = 'msg_comma_separation_place_holder',
  labelUploadLogoTitle = 'label_upload_logo_title',
  labelUploadLogoDescription = 'label_upload_logo_description',
  labelAccountTypePrefix = 'msg_account_type_',
  tooltipView = 'label_tooltip_view',

  msgTitleProviderReportStatistics = 'msg_title_provider_report_statistics',
  msgTitleProviderReportEmpStatistics = 'msg_title_provider_report_emp_statistics',
  labelFromDate = 'label_from_date',
  labelToDate = 'label_to_date',
  msgErrorStartEndDateLesser = 'msg_error_start_end_date_lesser',
  msgTitleProviderReportPerformanceStatistics = 'msg_title_provider_report_performance_statistics',
  labelAverage = 'label_average',
  msgNotifyUpdates = 'msg_notify_updates',

  tooltipMoveRowUp = 'tooltip_move_row_up',
  tooltipMoveRowDown = 'tooltip_move_row_down',
  buttonUp = 'button_up',
  buttonDown = 'button_down',
  titleDisplayOrder = 'title_display_order',

  fieldNotificationDate = 'field_notification_date',
  fieldCompanyNumber = 'field_company_number',
  fieldVatNumber = 'field_vat_number',
  msgNoRecordsFound = 'msg_no_records_found',
  labelProviderAutoComplete = 'label_provider_auto_complete',

  msgEmptySuggestions = 'msg_empty_suggestions',
  cookiesButtonSettings = 'cookies_button_settings',
  titleCookieSettings = 'title_cookie_settings',

  notificationCollaboratorAdded = 'notification_collaborator_added',
  labelHasSolicitor = 'label_has_solicitor',
  fieldLegalRepresentativeFirstName = 'field_legal_representative_first_name',
  fieldLegalRepresentativeLastName = 'field_legal_representative_last_name',
  fieldLegalRepresentativeEmail = 'field_legal_representative_email',
  fieldLegalRepresentativeTelephone = 'field_legal_representative_telephone',
  buttonRemoveLegalRepresentative = 'button_remove_legal_representative',
  tooltipDeathCasePermissionDenied = 'tooltip_death_case_permission_denied',
  msgErrorFieldFromDate = 'msg_error_field_from_date',
  msgErrorFieldFuneralDate = 'msg_error_field_funeral_date',
  msgErrorFieldDeathDate = 'msg_error_field_death_date',
  fieldTitle = 'field_title',
  fieldBuildingName = 'field_building_name',
  fieldBuildingNumber = 'field_building_number',
  titleNecessaryCookies = 'title_necessary_cookies',
  labelCookieRequired = 'label_cookie_required',
  tooltipCookieRequiredText = 'tooltip_cookie_required_text',
  msgNewRegisteredDeathCase = 'msg_new_registered_death_case',
  buttonConfirm = 'button_confirm',
  buttonReject = 'button_reject',
  modalConfirmRegisteredDeathCase = 'modal_confirm_registered_death_case',
  pageReferDeathCase = 'page_refer_death_case',
  labelBereavementEmail = 'label_bereavement_email',
  labelUserEmailId = 'label_user_email_id',
  labelChooseEmail = 'label_choose_email',
  titlePersonalDetails = 'title_personal_details',
  titleAddressDetails = 'title_address_details',
  descIdVerificationType = 'desc_id_verification_type',
  labelTransunion = 'label_transunion',
  labelYoti = 'label_yoti',
  helpTextTransunion = 'help_text_transunion',
  helpTextYoti = 'help_text_yoti',
  descUploadIdDocuments = 'desc_upload_id_documents',
  descTransunionSummary = 'desc_transunion_summary',
  labelTips = 'label_tips',
  descTipsName = 'desc_tips_name',
  descTipsAddress = 'desc_tips_address',
  msgBirthDateError = 'msg_birth_date_error',
  msgDocumentUpload = 'msg_document_upload',
  modalDeleteIdVerifyDocument = 'modal_delete_id_verify_document',
  msgDocumentDeleteSuccessfully = 'msg_document_delete_successfully',
  tooltipDeleteIdDocument = 'tooltip_delete_id_document',
  labelWillVisibilityItemPrefix = 'label_will_visibility_item_',
  fieldUnassignedPlaceholder = 'field_unassigned_placeholder',
  missingItemPrefix = 'missing_item_prefix_',
  transUnionHeaderPrefix = 'transunion_header_prefix_',
  msgRelationshipRequired = 'msg_relationship_required',
  msgLegalRelationshipRequired = 'msg_legal_relationship_required',

  msgFileSizeLarge = 'msg_file_size_large',
  msgFileFormatNotSupported = 'msg_file_format_not_supported',
  infoDocumentsTab = 'info_documents_tab',
  statusCompletedByLl = 'status_completed_by_ll',
  statusDescCompletedByLl = 'status_desc_completed_by_ll',

  descAdditionalContacts = 'desc_additional_contacts',
  labelNewContact = 'label_new_contact',
  fieldContactFirstName = 'field_contact_first_name',
  fieldContactLastName = 'field_contact_last_name',
  fieldContactEmail = 'field_contact_email',
  fieldContactPhone = 'field_contact_phone',
  fieldContactRelationship = 'field_contact_relationship',
  labelChooseMoreThanOne = 'label_choose_more_than_one',
  buttonAddContact = 'button_add_contact',
  buttonAddAnother = 'button_add_another',
  fieldContactNumber = 'field_contact_number',
  tooltipCollaboratorInfo = 'tooltip_collaborator_Info',

  labelAuthMobileMenuPrefix = 'label_auth_mobile_menu_',
  descAuthMobileMenuPrefix = 'desc_auth_mobile_menu_',

  labelAuthMenuItemPersonal = 'label_auth_menu_item_personal',
  labelAuthMenuItemBusiness = 'label_auth_menu_item_business',

  buttonCreateAccount = 'button_create_account',
  msgSignedOut = 'msg_signed_out',

  fieldEmailAddressPlaceholder = 'field_email_address_placeholder',
  fieldLoginPasswordPlaceholder = 'field_login_password_placeholder',
  labelNotRegistered = 'label_not_registered',
  linkSignUpHere = 'link_sign_up_here',
  linkSignInHere = 'link_sign_in_here',
  linkRegisterHere = 'link_register_here',
  subtitlePasswordReset = 'subtitle_password_reset',
  buttonRecoveryEmail = 'button_recovery_email',
  labelCheckYourEmail = 'label_check_your_email',
  labelAccountExistsEmailSent = 'label_account_exists_email_sent',
  labelEmailCheckSteps = 'label_email_check_steps',
  linkResendEmailResetPassword = 'link_resend_email_reset_password',
  linkPrivacyNotice = 'link_privacy_notice',
  labelMfaOptionOn = 'label_mfa_option_on',
  labelMfaOptionOff = 'label_mfa_option_off',
  labelDragFileIntoBox = 'label_drag_file_into_box',
  labelAcceptedFileFormats = 'label_accepted_file_formats',
  labelColloboratorsInfo = 'label_colloborators_info',
  labelActiveCollaborators = 'label_active_collaborators',

  tootltipWhyPending = 'tootltip_why_pending',
  tooltipWhyPendingDesc = 'tooltip_why_pending_desc',
  labelContactEmailPlaceholder = 'label_contact_email_placeholder',
  titleEmailVerifiedSuccess = 'title_email_verified_success',
  labelTip = 'label_tip',
  labelZeroDeathCase = 'label_zero_death_case',
  labelZeroRegisteredLives = 'label_zero_registered_lives',

  buttonAddCompanyToNotify = 'button_add_company_to_notify',
  labelNotificationStatusbriefPrefix = 'label_notification_status_brief_prefix_',
  subTitleConfirmationForm = 'sub_title_confirmation_form',
  labelWarnBeforeDeathCaseSave = 'label_warn_before_death_case_save',
  labelWarnBeforeLifeCaseSave = 'label_warn_before_life_case_save',
  labelNotificationOurRef = 'label_notification_our_ref',
  labelUploadedByNotifier = 'label_uploaded_by_notifier',
  labelFromYou = 'label_from_you',
  msgEnterMessage = 'msg_enter_message',
  fieldDeceasedPostcodeInput = 'field_deceased_postcode_input',
  fieldAddPreviousAddress = 'field_add_previous_address',
  fieldLifeCaseAddPostcode = 'field_life_case_add_postcode',
  fieldAccountEnterPostcode = 'field_account_enter_postcode',
  labelUploadedByNotifiedCompanies = 'label_uploaded_by_notified_companies',
  fieldCompanyPostCodeInput = 'field_company_post_code_input',
  labelYourNotifications = 'label_your_notifications',
  subtitleYourNotifications = 'subtitle_your_notifications',
  labelInProgress = 'label_in_progress',
  labelShowing = 'label_showing',
  labelNotifications = 'label_notifications',
  labelAssignee = 'label_assignee',
  labelDateRange = 'label_date_range',
  buttonApply = 'button_apply',
  labelOverview = 'label_overview',
  labelNotificationTimeReport = 'label_notification_time_report',
  labelSentToComplete = 'label_sent_to_complete',
  labelOpenedToComplete = 'label_opened_to_complete',
  labelDays = 'label_days',
  labelHours = 'label_hours',
  labelEmployeePerformance = 'label_employee_performance',
  labelEmployeeName = 'label_employee_name',
  labelActiveNotifications = 'label_active_notifications',
  labelCompletedNotifications = 'label_completed_notifications',
  labelTimePerNotification = 'label_time_per_notification',
  buttonDownloadSvgFile = 'button_download_svg_file',
  buttonYesAddCompanies = 'button_yes_add_companies',
  labelRegisterType = 'label_register_type',
  labelRegisterBereavement = 'label_register_bereavement',
  labelRegisterProfessional = 'label_register_professional',
  labelAsSeenIn = 'label_as_seen_in',
  labelWhySignUp = 'label_why_sign_up',
  labelTheBenefits = 'label_the_benefits',
  labelRegisterFree = 'label_register_free',
  labelRegisterLives = 'label_register_lives',
  linkBackToMenu = 'link_back_to_menu',
  msgUpdatedSuccessfully = 'msg_updated_successfully',
  msgCompanyRegisteredSuccessfully = 'msg_company_registered_successfully',
  msgAddressNotFound = 'msg_address_not_found',
  labelPersonalDetailsCheck = 'label_personal_details_check',
  labelProvideBasicDetails = 'label_provide_basic_details',
  labelProvideAccountDetails = 'label_provide_account_details',
  labelWorkAddress = 'label_work_address',
  labelTypeCompanyName = 'label_type_company_name',
  labelSearchByPostcode = 'label_search_by_postcode',
  labelSelectYourAddress = 'label_select_your_address',
  linkEnterAddressManually = 'link_enter_address_manually',
  fieldOptIn = 'field_opt_in',

  titleDeathCaseCreatedSuccess = 'title_death_case_created_success',
  infoDeathCaseCreated = 'info_death_case_created',
  labelQuestionSendNotifications = 'label_question_send_notifications',
  buttonSendDeathNotifications = 'button_send_death_notifications',
  buttonGoToDashboard = 'button_go_to_dashboard',
  titleChatRelatedNotification = 'title_chat_related_notification',
  labelChooseProviderOption = 'label_choose_provider_option',
  linkBackToSettings = 'link_back_to_settings',
  descCannotEditCase = 'desc_cannot_edit_case',
  titleCannotEditCase = 'title_cannot_edit_case',
  labelEnterYourPostcode = 'label_enter_your_postcode',
  placeholderTitle = 'placeholder_title',
  placeholderFirstName = 'placeholder_first_name',
  placeholderSurName = 'placeholder_sur_name',
  placeholderDateFormat = 'placeholder_date_format',
  titleGetProfessionalHelp = 'title_get_professional_help',
  subtitleGetProfessionalHelp = 'subtitle_get_professional_help',
  placeholderMiddleName = 'placeholder_middle_name',
  pageActivity = 'page_activity',
  subtitlePageActivity = 'subtitle_page_activity',
  labelReferralSourceOther = 'label_referral_source_other',
  labelReferralSourceCompany = 'label_referral_source_company',
  labelDeceasedInfo = 'label_deceased_info',
  labelDetailsNomatch = 'label_details_no_match',
  idCheckDetailsHeader = 'id_check_details_header',
  idCheckDetailsP1 = 'id_check_details_p1',
  idCheckDetailsP2 = 'id_check_details_p2',
  acceptableDocHeader = 'acceptable_doc_header',
  acceptableDocPassport = 'acceptable_doc_passport',
  acceptableDocdl = 'acceptable_doc_dl',
  acceptableDocBrp = 'acceptable_doc_brp',
  bodyMessageDeathNotification = 'body_msg_death_notification',
  bodyMessageLifeNotification = 'body_msg_life_notification',
  buttonMessageDeathNotification = 'btn_msg_death_notification',
  buttonMessageLifeNotification = 'btn_msg_life_notification',
  automatedReports = 'title_auto_reports',
  pageSpPortal = 'page_sp_portal',
}
